import React from "react";
import {createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import {COMPANY, COPYRIGHT, KMKR, REGISTRY_CODE} from "../shared/data";

const useStyles = makeStyles(({palette, typography}: Theme) => createStyles( {
    text: {
        color: palette.primary.main,
        fontSize: typography.body2.fontSize,
        textAlign: "center",
        display: "inline-block",
        margin: "0 auto",
    },
    details: {
        color: palette.primary.main,
        fontSize: typography.body2.fontSize,
        textAlign: "center",
        marginBottom: "10px"
    },
    footer: {
        marginTop: "14em"
    }
}));

export default function DesktopFooter(props: any) {
    const classes = useStyles(props)
    return (
        <footer className={classes.footer}>
            <Grid classes={{root: classes.details}} container justify="center" spacing={10}>
                <Grid key={1} item>
                    <span>{COMPANY}</span>
                </Grid>
                <Grid key={2} item>
                    <span>{REGISTRY_CODE}</span>
                </Grid>
                <Grid key={3} item>
                    <span>{KMKR}</span>
                </Grid>
            </Grid>
            <span className={classes.text}>{COPYRIGHT}</span>
        </footer>
    );
};