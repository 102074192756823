import {useState, useEffect, useCallback} from 'react';

export const WindowDimensions = () => {

    const hasWindow = typeof window !== 'undefined';

    const getWindowDimensions = useCallback(() => {
        const width = hasWindow ? window.innerWidth : null;
        const height = hasWindow ? window.innerHeight : null;
        return {width, height};
    }, [hasWindow])

    const initialDimensions = getWindowDimensions();
    const [dimensions, setDimensions] = useState(initialDimensions);

    useEffect(() => {
        if (hasWindow) {
            const handleResize = () => {
                const newDimensions = getWindowDimensions();
                setDimensions(newDimensions);
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [getWindowDimensions, hasWindow]);

    return dimensions;
}