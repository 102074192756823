import React from "react";
import {createStyles, Divider, Theme, makeStyles, GridList, GridListTile} from "@material-ui/core";
import 'react-under-construction/build/css/index.css';
import InfoItems from "../../InfoItems";

const useStyles = makeStyles(({palette}: Theme) => createStyles( {
    divider: {
        backgroundColor: palette.secondary.main,
        margin: "0 auto",
        marginTop: "1em",
        marginLeft: "0.5em",
        height: "2px",
        width: "97%"
    },
    info : {
        marginLeft: "2em",
        marginTop: "1em"
    }
}));

export default function MobileBody() {
    const classes = useStyles();

    return(
        <GridList cellHeight={"auto"} cols={1}>
            <GridListTile key={1} style={{height: "auto"}}>
                <Divider classes={{root: classes.divider}} orientation="horizontal"/>
            </GridListTile>
            <GridListTile key={2} style={{width: "auto", margin: "auto"}}>
                <div className={classes.info}>
                    <InfoItems view={"mobile"}/>
                </div>
            </GridListTile>
            <GridListTile key={3} style={{height: "auto"}}>
                <Divider classes={{root: classes.divider}} orientation="horizontal"/>
            </GridListTile>
        </GridList>);
}