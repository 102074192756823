import DesktopHeader from "../layout/desktop/DesktopHeader";
import DesktopBody from "../layout/desktop/DesktopBody";
import DesktopFooter from "../layout/desktop/DesktopFooter";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles(({palette, typography}: Theme) => createStyles( {
    container: {
        minHeight: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        fontFamily: typography.fontFamily
    }
}));

export default function DesktopView(props: any) {
    const classes = useStyles(props);
    return(<div className={classes.container}>
            <DesktopHeader/>
            <DesktopBody/>
            <DesktopFooter/>
        </div>);
}