import React from 'react';
import './App.css';
import MobileView from "./components/views/MobileView";
import DesktopView from "./components/views/DesktopView";
import {WindowDimensions} from "./utils/window-dimensions";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";

const MOBILE_WIDTH = 865;
const FONT_FAMILY = "Roboto";

const mainTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#2b4a8b"
        },
        secondary: {
            main: "#dcdb21"
        }
    },
    spacing: 2,
    typography: {
        fontFamily: FONT_FAMILY
    }
});

export default function App() {
    const {width} = WindowDimensions();
    return (
        <div className="App">
            <ThemeProvider theme={mainTheme}>
                {width && width < MOBILE_WIDTH ? <MobileView/> : <DesktopView/>}
            </ThemeProvider>
        </div>
  );
}