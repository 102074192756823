import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";
import {AccountBalanceOutlined, EmailOutlined, PhoneAndroidOutlined, LocationOnOutlined, NotListedLocationOutlined} from '@material-ui/icons';
import React from "react";

const PHONE = "+372 56216793";
const PHONE_LINK = `tel:${PHONE}`;
const EMAIL = "nutikas(at)nutikas.it";
const EMAIL_LINK = "mailto:nutikas@nutikas.it";
const LOCATION = "Pärnu mnt 388b, Tallinn";
const LOCATION_LINK = `https://maps.google.com/?q=${LOCATION}`;
const BANK = "LHV EE807700771001902834";
const FONT_SIZE = "inherit";

const useStyles = makeStyles(({palette, typography}: Theme) => createStyles({
    text: {
        textAlign: "center",
        color: palette.primary.main,
        marginTop: "14px",
        fontSize: '18px',
        textDecoration: "none",
        '@media (min-width: 865px)': {
            fontSize: '26px',
            marginTop: '21px',
            marginLeft: "5px",
        },
        fontFamily: "inherit"
    },
    icon: {
        fontSize: '46px',
        '@media (min-width:865px)': {
            fontSize: '64px',
        },
        fontFamily: "inherit"
    },
    hack: {
        marginLeft: "5px"
    }
}));

const getIcon = (type: string) => {
    switch(type) {
        case "email":
            return <EmailOutlined fontSize={FONT_SIZE}/>;
        case "phone":
            return <PhoneAndroidOutlined fontSize={FONT_SIZE}/>;
        case "location":
            return <LocationOnOutlined fontSize={FONT_SIZE}/>;
        case "bank":
            return <AccountBalanceOutlined fontSize={FONT_SIZE}/>;
        default:
            return <NotListedLocationOutlined fontSize={FONT_SIZE}/>;
    }
}

const getInfo = (type: string) => {
    switch(type) {
        case "email":
            return {href: EMAIL_LINK, text: EMAIL}
        case "phone":
            return {href: PHONE_LINK, text: PHONE}
        case "location":
            return {href: LOCATION_LINK, text: LOCATION}
        case "bank":
            return {text: BANK}
        default:
            return {}
    }
}

const getText = (href: string | undefined, text: string | undefined, classes: any) => {
    if (href) {
        return <a href={href} className={classes.text} rel="noopener noreferrer" target="_blank">{text}</a>;
    }
    return <span className={classes.text}>{text}</span>;
}

export default function InfoItem(props: any) {
    const classes = useStyles(props);
    const type = props.type;
    const {href, text} = getInfo(type);

    return (
        <>
            <div className={classes.icon}>
                {getIcon(type)}
            </div>
            {getText(href, text, classes)}
            {/* hack to fix clipping of text line */}
            <span className={classes.hack}/>
        </>);
}