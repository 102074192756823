import React from 'react';
import MobileHeader from "../layout/mobile/MobileHeader";
import MobileBody from "../layout/mobile/MobileBody";
import MobileFooter from "../layout/mobile/MobileFooter";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const useStyles = makeStyles(({typography}: Theme) => createStyles( {
    container: {
        fontFamily: typography.fontFamily,
        overflow: "auto",
        maxHeight: "100vh"
    }
}));

export default function MobileView(props: any) {
    const classes = useStyles(props);
    return(<div className={classes.container}>
                <MobileHeader/>
                <MobileBody/>
                <MobileFooter/>
            </div>);
}