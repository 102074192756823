import React from "react";
import {createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import logo from "../../../images/logo.jpg";
import {KEYWORDS} from "../shared/data";

const useStyles = makeStyles(({palette}: Theme) => createStyles( {
    text: {
        color: palette.primary.main,
        fontSize: "18px",
        display: "table",
        margin: "auto"
    },
    logo: {
        marginTop: "1em"
    },
    keywords: {
        display: "inline-block",
        marginTop: "1em"
    }
}));

function MobileHeader(props: any) {
    const classes = useStyles(props);
    return (
        <>
            <div className={classes.logo}>
                <img alt={"logo"} src={logo} height={250} width={255}/>
            </div>
            <Grid classes={{root: classes.keywords}} container justify="center" spacing={2}>
                {KEYWORDS.map((word, index) => {
                    return <Grid container spacing={1} key={index} item>
                        <span className={classes.text}>{word}</span>
                    </Grid>;
                })}
            </Grid>
        </>
    );
}

export default MobileHeader
