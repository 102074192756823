import {Grid} from "@material-ui/core";
import InfoItem from "./InfoItem";
import React from "react";
import {CONTACT_ITEMS} from "./layout/shared/data";

export default function InfoItems(props: any) {
    return(<>
            {CONTACT_ITEMS.map((item, index) => {
                return <Grid container spacing={3} key={index} item>
                    <InfoItem {...props} type={item}/>
                </Grid>
            })}
        </>);
}