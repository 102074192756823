import React from "react";
import {createStyles, Grid, makeStyles, Theme} from "@material-ui/core";
import {COMPANY, COPYRIGHT, KMKR, REGISTRY_CODE} from "../shared/data";

const useStyles = makeStyles(({palette, typography}: Theme) => createStyles( {
    copyright: {
        color: palette.primary.main,
        fontSize: typography.body2.fontSize,
        textAlign: "center",
        display: "table",
        margin: "auto",
        marginTop: "1em"
    },
    details: {
        color: palette.primary.main,
        fontSize: typography.body2.fontSize,
        textAlign: "center",
        display: "table",
        margin: "auto"
    },
    footer: {
        marginTop: "1em",
        width: "100%",
        height: "100%",
        textAlign: "center",
    }
}));

export default function MobileFooter(props: any) {
    const classes = useStyles(props)
    return (
        <footer className={classes.footer}>
            <Grid classes={{root: classes.details}} container justify="center" spacing={5}>
                <Grid container spacing={1} key={1} item>
                    <span className={classes.details}>{COMPANY}</span>
                </Grid>
                <Grid container spacing={1} key={2} item>
                    <span className={classes.details}>{REGISTRY_CODE}</span>
                </Grid>
                <Grid container spacing={1} key={3} item>
                    <span className={classes.details}>{KMKR}</span>
                </Grid>
            </Grid>
            <span className={classes.copyright}>{COPYRIGHT}</span>
        </footer>
    );
};