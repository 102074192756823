import React from "react";
import {Container, createStyles, Divider, Grid, Theme, makeStyles} from "@material-ui/core";
import 'react-under-construction/build/css/index.css';
import logo from '../../../images/logo.jpg'
import InfoItems from "../../InfoItems";
import {KEYWORDS} from "../shared/data";

const useStyles = makeStyles(({palette, typography}: Theme) => createStyles( {
    text: {
        color: palette.primary.main,
        fontSize: "26px",
        textAlign: "center",
        display: "inline-block",
        margin: "0 auto",
    },
    divider: {
        backgroundColor: palette.secondary.main,
        width: "4px",
        marginLeft: "60px",
        marginRight: "25px"
    },
    container: {
        marginTop: "10em",
        width: "100%",
        height: "100%",
        textAlign: "center",
    },
    center: {
        display: "inline-block"
    },
    keywords: {
        marginTop: "50px"
    }
}));

export default function DesktopBody(props: any) {
    const classes = useStyles(props);
    const keywords = KEYWORDS.slice(0, KEYWORDS.length-1).join(' - ');
    const cities = KEYWORDS[KEYWORDS.length-1];

    return(
        <Container className={classes.container}>
            <Grid container justify="center" spacing={10}>
                <Grid key={1} item>
                    <img alt={"logo"} src={logo} height={300} width={305}/>
                </Grid>
                <Grid key={2} item>
                    <Divider classes={{root: classes.divider}} orientation="vertical" />
                </Grid>
                <Grid key={3} item>
                    <InfoItems/>
                </Grid>
            </Grid>
            <Grid classes={{root: classes.keywords}} container justify="center" spacing={10}>
                <Grid container spacing={3} item>
                    <span className={classes.text}>{keywords}</span>
                </Grid>
                <Grid container spacing={3} item>
                    <span className={classes.text}>{cities}</span>
                </Grid>
            </Grid>
        </Container>);
}